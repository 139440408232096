
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-ExtraLight.ttf');
  font-weight: 200;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-Light.ttf');
  font-weight: 300;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-Regular.ttf');
  font-weight: 400;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-SemiBold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'Nunito Sans';
  src: url('./assests/fonts/nunitoSans/NunitoSans_10pt-ExtraBold.ttf');
  font-weight: 800 900;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assests/fonts/openSans/OpenSans-ExtraBold.ttf');
  font-weight: 800 900;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
